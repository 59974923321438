<template>
  <div class="padding-20">
    <search-form
      ref="searchFormRef"
      :items="items"
      @fnSearch="handleFnSearch"
    />
    <grid
      ref="gridRef"
      bordered
      size="small"
      :columns="columns"
      :code="code"
      :url="url"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ y: gridHeight }"
    >
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
  </div>
  <a-modal v-model:visible="visible" title="编辑" centered @ok="handleOk">
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
      <a-form-item v-if="!form.id" label="编码" v-bind="validateInfos.code">
        <template #extra>
          <div>
            建议使用 :
            号作为分隔符，并以view、add、update、delete、export、import、download、upload等关键词结尾。
          </div>
          <div>如：menu:add、user:view、file:upload等。</div>
        </template>
        <a-input v-model:value="form.code" placeholder="请输入编码" />
      </a-form-item>
      <a-form-item label="名称" v-bind="validateInfos.name">
        <a-input v-model:value="form.name" placeholder="请输入名称" />
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea v-model:value="form.remark" placeholder="请输入描述" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, ref, toRaw, toRefs, watch } from 'vue';
import { Form, message } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import resourcesApi from '@/api/resources';

export default {
  name: 'Resources',
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    SearchForm,
    Grid,
    Operation,
  },
  props: ['code', 'menuId'],
  setup(props) {
    const required = { required: true, message: '不能为空' };
    const searchFormRef = ref();
    const gridRef = ref();
    const state = reactive({
      search: { menuId: props.menuId },
      visible: false,
    });
    const form = reactive({
      id: '',
      code: '',
      name: '',
      remark: '',
      menuId: props.menuId,
    });
    const { resetFields, validate, validateInfos } = Form.useForm(form, {
      code: [required],
      name: [required],
    });
    watch(
      () => props.menuId,
      () => searchFormRef.value.resetFields()
    );
    return {
      items: [
        { key: 'code', label: '编码' },
        { key: 'name', label: '名称' },
      ],
      columns: [
        { dataIndex: 'code', title: '编码' },
        { dataIndex: 'name', title: '名称' },
        {
          dataIndex: 'id',
          title: '操作',
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'resources:add',
          fnClick: () => {
            state.visible = true;
            resetFields();
            form.menuId = props.menuId;
          },
        },
      ],
      options: [
        {
          type: 'update',
          permission: 'resources:update',
          fnClick: record => {
            state.visible = true;
            Object.assign(form, record);
          },
        },
        {
          type: 'delete',
          permission: 'resources:delete',
          fnClick: ({ id }) =>
            resourcesApi.delete('resources:delete', { ids: [id] }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            }),
        },
      ],
      url: resourcesApi.pageUrl,
      gridHeight: document.body.clientHeight - 510,
      searchFormRef,
      gridRef,
      ...toRefs(state),
      form,
      validateInfos,
      handleFnSearch: value => {
        state.search = { ...value, menuId: props.menuId };
      },
      handleOk: () =>
        validate().then(() => {
          let type = 'add';
          form.id && (type = 'update');
          resourcesApi[type](`resources:${type}`, toRaw(form)).then(() => {
            state.visible = false;
            message.success('操作成功');
            gridRef.value.refreshGrid();
          });
        }),
    };
  },
};
</script>
